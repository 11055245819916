<template>
  <!-- 预览状态wps -->
  <div :id="wpsFileId"></div>
</template>
<script>
import Vue from "vue";

import {
  setWordListener,
  setPPTListener,
  setPDFListener,
  setXLSListener,
  removeWordListener,
  removePPTListener,
  removeXLSListener,
  removePDFListener,
} from "../../../utils/wps-listener";

export default {
  name: "teachingLession",
  props: {
    wpsUrl: {
      type: String,
      default: "",
    },
    token: {
      type: String,
      default: "",
    },
    isShowTopArea: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: "",
    },
    wpscurryPage: {
      type: Number,
    },
    element: {
      type: String,
      default: "newwpsfile",
    },
    wpsFileId: {
      type: String,
      default: "teachwpsFile",
    },
    wpsConfig: {
      type: String,
      default: "wpsConfig",
    },
    isCache: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      teststate: true,
      simpleMode: false, // 是否开启简易模式
      jssdk: null,
      officeType: "",
      interval: null,
    };
  },
  watch: {
    wpsUrl() {
      this.openWps(this.wpsUrl, this.token);
    },
  },
  mounted() {
    // this.$emit("onFullscreen");
    this.openWps(this.wpsUrl, this.token);
    // window.addEventListener("click", this.pageChange);
    // this.openWps(this.aaa, this.bbb);
    // this.test();
  },
  beforeDestroy() {
    console.log("beforeDestroy=============");
    clearInterval(this.interval);
    this.removeListiner(this.jssdk);
  },
  methods: {
    async openWps(url, token) {
      let _this = this;

      this.jssdk = _this.wps.config({
        mode: _this.simpleMode ? "simple" : "normal",
        mount: document.querySelector(`#${_this.element}`),
        wpsUrl: url,
        pptOptions: {
          isInSafeMode: true, // 安全模式
          isShowRemarkView: false,
        },
        commonOptions: {
          isShowTopArea: _this.isShowTopArea, // 隐藏顶部区域(头部和工具栏)
          isBrowserViewFullscreen: true, // 是否在浏览器区域全屏 true禁止全屏
          isIframeViewFullscreen: false, // 是否在 iframe 区域内全屏
        },
        commandBars: [
          {
            cmbId: "MoreMenus", // 组件 控制更多菜单隐藏
            attributes: {
              visible: false, // 隐藏组件
            },
          },
          {
            cmbId: "FloatQuickHelp", // 组件 页面定制对象：右下角帮助（金小獴）
            attributes: {
              visible: false, // 隐藏组件
            },
          },
          {
            cmbId: "TabPrintPreview", // 组件 页面定制对象：打印
            attributes: {
              visible: false, // 隐藏组件
            },
          },
          {
            cmbId: "HeaderHistoryMenuBtn", // 组件 顶部状态栏-历史记录菜单按钮
            attributes: {
              visible: false, // 隐藏组件
            },
          },
          {
            cmbId: "WPPPcCommentButton", // 组件 演示-底部工具栏-评论按钮
            attributes: {
              visible: false, // 隐藏组件
            },
          },
          {
            cmbId: "TabReviewTab", // 组件 演示-工具栏-审阅按钮
            attributes: {
              visible: false, // 隐藏组件
            },
          },
          {
            cmbId: "TabReviewWord", // 组件 word工具栏-审阅按钮
            attributes: {
              visible: false, // 隐藏组件
            },
          },
          {
            cmbId: "ContextMenuConvene", // 组件 word-右键-召唤在线协助者
            attributes: {
              visible: false, // 隐藏组件
            },
          },
          {
            cmbId: "BookMarkContextMenu", // 组件 文字-右键-插入书签
            attributes: {
              visible: false, // 隐藏组件
            },
          },
          {
            cmbId: "PlayComponentToolbar", // 组件 全屏播放时 Toolbar
            attributes: {
              visible: false, // 隐藏组件
            },
          },
        ],
        onToast: ({ msg: 当前浏览器不支持全屏播放, action: close }) => {},
      });
      this.jssdk.on("fileOpen", (data) => {
        console.log("文件打开", data);
        if (!data.success) {
          console.log("文件打开失败");
        } else {
          this.officeType = data.fileInfo.officeType || "";
        }
      });
      this.jssdk.setToken({ token });
      Vue.prototype[this.wpsConfig] = this.jssdk; // 全局定义wpsConfig，通过this.wpsConfig调用改变wps

      let app = this.jssdk.Application;
      await this.jssdk.ready();
      console.log("wps", this.jssdk);
      switch (this.officeType) {
        case "w":
          setWordListener(this.jssdk, this.handleActionCallback);
          break;
        case "p":
          setPPTListener(this.jssdk, this.handleActionCallback);
          _this.setwps(this.jssdk);
          break;
        case "s":
          setXLSListener(this.jssdk, this.handleActionCallback);
          break;
        case "f":
          setPDFListener(this.jssdk, this.handleActionCallback);
          _this.setPDF(this.jssdk);
          break;

        default:
          setWordListener(this.jssdk, this.handleActionCallback);
          _this.setwps(this.jssdk);
          break;
      }
    },
    handleActionCallback(data) {
      // console.log("data", data);
      this.$emit("initPageAction", data);
    },

    removeListiner(wps) {
      switch (this.officeType) {
        case "w":
          removeWordListener(wps, this.handleActionCallback);
          break;
        case "p":
          removePPTListener(wps, this.handleActionCallback);
          break;
        case "s":
          removeXLSListener(wps, this.handleActionCallback);
          break;
        case "f":
          removePDFListener(wps, this.handleActionCallback);
          break;

        default:
          removeWordListener(wps, this.handleActionCallback);
          break;
      }
    },

    /**
     * ppt格式设置
     */
    // async setwps(wps) {
    //   let _this = this;

    //   await wps.ready();
    //   const app = wps.Application;
    //   // const WPPPcCommentButton = await app.CommandBars("WPPPcCommentButton"); // 页面定制对象：演示-底部工具栏-评论按钮
    //   // WPPPcCommentButton.Visible = false;
    //   // const TabReviewTab = await app.CommandBars("TabReviewTab"); // 页面定制对象：演示-工具栏-审阅按钮
    //   // TabReviewTab.Visible = false;
    //   // 获取状态
    //   // Slide 设置对象
    //   let SlideShowSettings = await app.ActivePresentation.SlideShowSettings;
    //   // 进入幻灯片播放模式
    //   await SlideShowSettings.Run();
    //   document.querySelector("#newwpsfile iframe").focus();
    //   // 修改工具栏样式
    //   // 获取当前页码
    //   const curryPage = await app.ActivePresentation.SlideShowWindow.View.Slide
    //     .SlideIndex;
    //   if (curryPage != 1) {
    //     // 跳转到指定页
    //     await app.ActivePresentation.SlideShowWindow.View.GotoSlide(1);
    //   }
    //   // 窗口对象
    //   const SlideShowWindow = await app.ActivePresentation.SlideShowWindow;
    //   // 视图对象
    //   const view = await SlideShowWindow.View;
    //   view.ShowPage = true; // 【播放模式】显示页码
    //    const menu =await app.Enum.PpToolType.pcPlayingMenu // 右键菜单
    //   view.SetToolVisible(menu, false);//隐藏右键功能弹框
    //   const presentation = await app.ActivePresentation;

    //   // 幻灯片对象
    //   const slides = await presentation.Slides;

    //   // 获取总页数
    //   const count = await slides.Count;
    //   await _this.$emit("changewpscurryPage", count, 2);
    //   // await SlideShowSettings.SetPlayToolbarPosition({
    //   //   Style: {
    //   //     Show: { bottom: "30px", top: "auto", right: "calc(50% - 138px)" }, // style 样式，工具栏显示时的位置
    //   //     Hidden: { bottom: "-100px", top: "auto", right: "calc(50% - 138px)" }, // style 样式，工具栏隐藏时的位置
    //   //   },
    //   // });
    //   // 修改画笔工具样式
    //   await SlideShowSettings.SetPlayInkPosition({
    //     Style: { bottom: "50px", top: "auto" }, // 画笔工具样式
    //   });
    //   // 监听退出幻灯片全屏播放
    //   app.Sub.SlideShowEnd = async () => {
    //     console.log("退出");
    //     _this.$emit("changdio");
    //   };
    //   // 监听进入幻灯片全屏播放
    //   app.Sub.SlideShowBegin = async () => {
    //     console.log("进入");
    //     view.ShowPage = true;
    //   };
    //   // 监听当前页改变事件
    //   app.Sub.SlideSelectionChanged = async (curryPages) => {
    //     // _this.wpscurryPage = curryPages;
    //     await _this.$emit("changewpscurryPage", curryPages, 1);
    //   };
    // },
    async pageChange() {
      let _this = this;
      // eslint-disable-next-line no-undef
      await wps.ready();
      // eslint-disable-next-line no-undef
      const app = wps.Application;
      app.Sub.SlideSelectionChanged = async (curryPage) => {
        console.log("切换到：", curryPage);
      };
    },
    // ppt跳转页面
    async gotoSlide(count) {
      const app = this.jssdk.Application;
      switch (this.officeType) {
        case "w":
          break;
        case "p":
          await app.ActivePresentation.SlideShowWindow.View.GotoSlide(count);

          break;
        case "s":
          break;
        case "f":
          setPDFListener(this.jssdk, this.handleActionCallback);
          // 跳转到指定页
          await app.ActivePDF.JumpToPage(count);

          break;

        default:
          await app.ActivePresentation.SlideShowWindow.View.GotoSlide(count);

          break;
      }
    },
    // 初始化缓存操作
    initCache(curryPage, wpscount) {
      if (this.isCache) {
        let count = 1;
        if (!this.interval) {
          this.interval = setInterval(() => {
            if (curryPage < wpscount) {
              curryPage = count++;
              this.gotoSlide(curryPage);
            } else {
              this.$emit("setCurrentCacheStatus", 1);
              clearInterval(this.interval);
            }
          }, 500);
        }
      }
    },
    // pdf 配置
    async setPDF(wps) {
      let _this = this;
      const app = wps.Application;
      if (app.ActivePDF) {
        const curryPage = await app.ActivePDF.CurrentPage;
        const count = await app.ActivePDF.PagesCount;
        this.initCache(1, count);
      }
    },
    async setwps(wps) {
      let _this = this;
      // await wps.ready();
      const app = wps.Application;
      if (app.ActivePresentation) {
        // Slide 设置对象
        let SlideShowSettings =
          app.ActivePresentation &&
          (await app.ActivePresentation.SlideShowSettings);
        // 进入幻灯片播放模式
        SlideShowSettings && (await SlideShowSettings.Run());
        await _this.$emit("setinitdata", true);
        // app.Enum.PpToolType.pcPlayingMenu = "pc_play_hover_link";
        // await console.log("app", app.Enum.PpToolType)
        // await app.ActivePresentation.SlideShowWindow.View.Exit();
        // 演示文稿对象
        const presentation = await app.ActivePresentation;
        // 幻灯片对象
        const slides = presentation && (await presentation.Slides);
        // 获取总页数
        const count = await slides.Count;
        await _this.$emit("changewpscurryPage", count, 2);
        // 获取当前页码
        const curryPage = await app.ActivePresentation.SlideShowWindow.View
          .Slide.SlideIndex;

        if (curryPage != 1) {
          // 跳转到指定页
          await _this.$emit("setbuttonstate", false, true);
          await app.ActivePresentation.SlideShowWindow.View.GotoSlide(1);
        } else {
          if (count == 1) {
            _this.$emit("setbuttonstate", false, false);
            this.$parent.setbuttonstate(false, false);
          }
          if (count > 1) {
            if (curryPage == 1) {
              _this.$emit("setbuttonstate", false, true);
            } else {
              if (curryPage == count) {
                _this.$emit("setbuttonstate", true, false);
              } else {
                _this.$emit("setbuttonstate", true, true);
              }
            }
          }
        }
        this.initCache(1, count);
        const SlideShowWindow = await app.ActivePresentation.SlideShowWindow;
        // 视图对象
        const view = await SlideShowWindow.View;
        // view.ShowPage = true; // 【播放模式】显示页码
        // app.ActivePresentation.SlideShowSettings.SetMiniThumbnailVisible(true)//可以跳出页码预览
        const menu = await app.Enum.PpToolType.pcPlayingMenu; // 右键菜单
        view.SetToolVisible(menu, false);
        // 监听退出幻灯片全屏播放
        app.Sub.SlideShowEnd = async () => {
          console.log("退出全屏播放");
          _this.$emit("setinitdata", false);
        };
        // 监听进入幻灯片全屏播放
        app.Sub.SlideShowBegin = async () => {
          console.log("进入全屏播放");
          view.ShowPage = true;
          _this.$emit("setinitdata", true);
        };
        // eslint-disable-next-line require-atomic-updates
        app.Sub.SlideSelectionChanged = async (curryPage) => {
          // _this.wpscurryPage = curryPage;
          await _this.$emit("changewpscurryPage", curryPage, 1);
          const presentation = await app.ActivePresentation;
          // 幻灯片对象
          const slides = await presentation.Slides;
          // 获取总页数
          const count = await slides.Count;
          const nowPage = await app.ActivePresentation.SlideShowWindow.View
            .Slide.SlideIndex;
          if (count == 1) {
            _this.$emit("setbuttonstate", false, false);
          }
          if (count > 1) {
            if (nowPage == 1 || _this.wpscurryPage == 1) {
              _this.$emit("setbuttonstate", false, true);
            } else {
              if (nowPage == count || _this.wpscurryPage == count) {
                _this.$emit("setbuttonstate", true, false);
              } else {
                _this.$emit("setbuttonstate", true, true);
              }
            }
          }
        };
        // await app.ActivePresentation.SlideShowWindow.View.Exit();
        // const moreMenus = await app.CommandBars("MoreMenus"); // 控制更多菜单隐藏
        // moreMenus.Visible = false;
        // const FloatQuickHelp = await app.CommandBars("FloatQuickHelp"); // 页面定制对象：右下角帮助（金小獴）
        // FloatQuickHelp.Visible = false;
        // const TabPrintPreview = await app.CommandBars("TabPrintPreview"); // 页面定制对象：打印
        // TabPrintPreview.Visible = false;
        // const HeaderHistoryMenuBtn = await app.CommandBars(
        //   "HeaderHistoryMenuBtn"
        // ); // 页面定制对象：顶部状态栏-历史记录菜单按钮
        // HeaderHistoryMenuBtn.Visible = false;
        // const WPPPcCommentButton = await app.CommandBars("WPPPcCommentButton"); // 页面定制对象：演示-底部工具栏-评论按钮
        // WPPPcCommentButton.Visible = false;
        // const TabReviewTab = await app.CommandBars("TabReviewTab"); // 页面定制对象：演示-工具栏-审阅按钮
        // TabReviewTab.Visible = false;

        // const TabThumbNumber = await app.CommandBars("TabThumbNumber"); // 页面定制对象：演示-工具栏-审阅按钮
        // TabThumbNumber.Visible = false;
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
